import {
  C,
  Data,
  Lucid,
  Tx,
} from 'lucid-cardano'
import * as L from 'lucid-cardano'
import UtilScripts from './scripts/utils.json'
import NewUtilScripts from './scripts/new-utils.json'
import OadaScripts from './scripts/oada.json'

import {Validator} from './types'
import {mkScriptUtils, wrapRedeemer} from './utils'

import {
  CollateralAmoDatum,
  CollateralAmoRedeemer,
  DonationDatum,
  MintNft,
  StakeAuctionBidDatum,
  BatchStakeDatum,
  StakingAmoDatum,
  StrategyDatum,
  StrategyRedeemer,
  _x,
  collateralAmoDatumSchema,
  stakeAuctionBidDatumSchema,
  batchStakeDatumSchema,
  stakingAmoDatumSchema,
  strategyDatumSchema,
  toWrappedData,
  BatchStakeRedeemer,
  StakeAuctionRedeemer,
  StakeAuctionParams,
  DexStrategyDatum
} from "./datums";
import { fromPlutusData, toData, toPlutusData } from "../bond/schema";
import { AssetClass, assetClassSchema } from "../bond/plutus-v1-encoders";

// hack to force evaluate the datums module until I can figure out the right way
const _xx = _x
 
export enum WhitelistProtocol {
  OTOKEN = 0
}

export enum OtokenAsset {
  OADA = 0,
  OOTHER = 1
}

export enum OtokenWhitelistPurpose {
  OTOKEN_RULE = 0,
  SOTOKEN_RULE = 1,
  CONTROLLER = 2,
  STRATEGY = 3,
  STAKE_AUCTION = 4
}

const minBigInt = (x: bigint, y: bigint) => x < y ? x : y
const maxBigInt = (x: bigint, y: bigint) => x > y ? x : y

export type OtokenParams = {
  baseAsset: AssetClass
  soulToken: AssetClass
  feeClaimer: L.Address
  feeClaimerToken: AssetClass
  sotokenLimit: bigint
  odaoFeeBps: bigint
  minimumDepositLovelace: bigint
  stablePoolLpToken: AssetClass
  stablePoolNft: AssetClass
  stablePoolDepositGuard: [bigint, bigint]
  stablePoolAssetOrder: bigint
  amplCoeff: bigint
  lpFeeNum: bigint
  protocolFeeNum: bigint
  controllerPubKeyHash: L.KeyHash
  collateralAmoTokenName: string
  stakingAmoTokenName: string
  donationStrategyTokenName: string
  stakeAuctionStrategyTokenName: string
  dexStrategyTokenName: string
  seedMaster: L.Address
}

export type StakingAmoParams = Awaited<ReturnType<OtokenSystem['getStakingAmoParams']>>

export type OtokenId = 'StakingAmo' | 'CollateralAmo'
export type OtokenRule = 'OtokenRule' | 'sOtokenRule' | 'FeeClaimRule' | 'DexStrategyRule'
export type OtokenStrategy = 'DonationStrategy' | 'DexStrategy'
export const initOtoken = async ({
  lucid,
  params: {
    baseAsset,
    soulToken,
    feeClaimerToken,
    feeClaimer,
    minimumDepositLovelace,
    stablePoolLpToken,
    stablePoolNft,
    stablePoolDepositGuard,
    stablePoolAssetOrder,
    controllerPubKeyHash,
    collateralAmoTokenName,
    stakingAmoTokenName,
    donationStrategyTokenName,
    stakeAuctionStrategyTokenName,
    dexStrategyTokenName,
    seedMaster
  }
}: {
  lucid: Lucid
  params: OtokenParams
}) => {
  const utils = new L.Utils(lucid)
  const scriptUtils = mkScriptUtils(lucid)

  const {
    loadValidator,
  } = scriptUtils;

  const baseAssetEnum = baseAsset.currencySymbol === '' ? OtokenAsset.OADA : OtokenAsset.OOTHER
  const whitelistNamespace = WhitelistProtocol.OTOKEN * 10000 + baseAssetEnum * 100
  const epochBoundary = 1_647_899_091_000n
  const epochLength = 432_000_000n
  const baseAssetUnit = (baseAsset.currencySymbol + baseAsset.tokenName) || 'lovelace'
  const soulTokenData = toPlutusData(soulToken)
  const otokenRuleWhitelist = loadValidator(
    UtilScripts,
    'whitelist.mint',
    [soulTokenData, BigInt(whitelistNamespace + OtokenWhitelistPurpose.OTOKEN_RULE)]
  )
  const sotokenRuleWhitelist = loadValidator(
    UtilScripts,
    'whitelist.mint',
    [soulTokenData, BigInt(whitelistNamespace + OtokenWhitelistPurpose.SOTOKEN_RULE)]
  )
  const controllerWhitelist = loadValidator(
    UtilScripts,
    'whitelist.mint',
    [soulTokenData, BigInt(whitelistNamespace + OtokenWhitelistPurpose.CONTROLLER)]
  )
  const strategyWhitelist = loadValidator(
    UtilScripts,
    'whitelist.mint',
    [soulTokenData, BigInt(whitelistNamespace + OtokenWhitelistPurpose.STRATEGY)]
  )
  const stakeAuctionWhitelist = loadValidator(
    NewUtilScripts,
    'whitelist.mint',
    [soulTokenData, BigInt(whitelistNamespace + OtokenWhitelistPurpose.STAKE_AUCTION)]
  )
  const collateralAmo = loadValidator(
    OadaScripts,
    'collateral_amo.spend',
    [soulTokenData, controllerWhitelist.hash, strategyWhitelist.hash]
  )
  const otokenPolicy = loadValidator(
    OadaScripts,
    'otoken_policy.mint',
    [otokenRuleWhitelist.hash]
  )
  const sotokenPolicy = loadValidator(
    OadaScripts,
    'otoken_policy.mint',
    [sotokenRuleWhitelist.hash]
  )

  const refUtxoMap: { [scriptHash: string]: { outRef: string, utxo?: L.UTxO } } = {
    "903d444cc678f9a5993e6f260325e63d193e9d976a6c389e95c9c08e": {
      outRef: "a895a59bbc893dba1783c79484d686cef6dd54e4e4d56a7b52468bf26cbc04f2#0"
    },
    "380b35d7911da830f69a5ddbb2990781fb97313738b7afed88c59ed5": {
      outRef: "63db97e2394c11742d9f66fd0b34499ecba6df34a1d1b1b4ee67135d4b7dce2b#0"
    },
    "6f19c57f1a8a16c36ddb03e066474dcb272c68aa8a136f25505fbfd1": {
      outRef: "cfb4f16f804e2f4eedac081451754db0ee2e6759182c966aca52fd494effd7e7#0"
    },
    "dd55f231e23e63678e4c6c4367071c7b092e735ab9c3db6d36a0b08f": { 
			outRef: "806bf5f1cda8829e1a91506f2f3f1d24caeb0a4caa1f20ff366dac471b4bfbf4#0" 
		},
    "f6db94fad6b06f804fc36cc665df0291fbfc42b979cd388b99d8fbf5": { 
			outRef: "e1c354e7b9034423c2c3f964a2121142df3fd685d8af9f53da5a1d13fc543464#0" 
		},
    "664569dde707eb3c2ff4c9283f4031f5cd2038668e9f1d50cb728f74": { 
			outRef: "cbf57035055e1c9b8f346162cb811cf0b05561e6f071c8c19c140a375e5dbd0e#0" 
		},
    "1075476c62cdc3b51a340fa392f2ef03a177d5a7a1451a059c957dfb": { 
			outRef: "6018418d749a6464713efed321aa4da1f4cb71227a434c8967e9c5397551788a#0" 
		},
    "909d7f282d8696217b556a8522c46d9fb08aac62a3327013bb40ed6b": { 
			outRef: "c4c60b7769cad2fce1e76c76630dfc1244b77324faa370c27c4993f9c10bd8bd#0" 
		},
    "339a00ccbe238ea1842ba92b9cdcb0016fad3d1896eb1209ce0ed7f5": { 
			outRef: "5eba4a5903bccfea9b65f384bfc4f8881b43550e8196b181aeb7d36a609c5380#0" 
		},
    "3a0f80f1c98fdd50364b69bd588e6b91042909509ca28bce7910547d": { 
			outRef: "0f8b44a3d6bcee58bb0ab9a2370d3a786e04469e5447a78b1157a9a971b78eba#0" 
		},
    "c6d4c7af979c7af77cfac2c1c9f0ce9ccc0ea92a7915c54ce3da6569": { 
			outRef: "ce9099b3ccaa24f83ff130cb77e1e48f7ac23eb8b68948b0fe8f5ea21e5ba1ab#0" 
		},
    "f6099832f9563e4cf59602b3351c3c5a8a7dda2d44575ef69b82cf8d": { 
			outRef: "7e78fb2ebf82e9a16ad27fc9a8cafa24dc3446f944859c9621f8e41afd58a514#0" 
		},
    "ce0a9beb82234239e8e4194527f05aca3dd5ecde1221e7fe06c3f630": { 
			outRef: "9c59bf7dd607df4ba7f27de2ad6ea3bb98837d2d325fe88dab55496a41f7dd5e#0" 
		},
    "5e96822160e48edc1216656f35061379645b328fb77e3292d9e36271": { 
			outRef: "c232d3fe14a7de42a3b4f9034b30d41dc3944621d23a4980fed8eac13db72289#0" 
		},
    "02a574e2f048e288e2a77f48872bf8ffd61d73f9476ac5a83601610b": { 
			outRef: "80d58a6e8d5191f8f01d5bca9de3bce1a43b11d02e61e1c3a6f0996ae9976c5e#0" 
		},
    "ca8b02920131f6c0b30c12bfffed8b0a9d3cc7d046eb0372caa02694": { 
			outRef: "9ad8e50c6c269cb8742705bf54a24b5006d8be31c64035d0213ca1d31cc9452a#0" 
		},
    "5d3df99fcfbbf282bd76a3d76a2e30bdd22e61c56f1462447938933b": { 
			outRef: "43da5e009b4c7d8594c5dc51d3901b89da9d64d208b7b2fa8980a90c42601132#0" 
		},
    "eeed2b7f8deded8011bd05628a9f440cd01bceb70496159cc03a5d4f": { 
			outRef: "dcbf871dff86a785e38fa98d7a25e399a8f19dbd39cd715ff05c6da2ac8840fb#0" 
		},
    "4fa34a7b4d48767f87f41fc6494b16cfdb4aaf497a694ec571c16cb4": { 
			outRef: "f67a4e0593d8124575c30d5a27e78a14017474f3bfb35c85f999042327420a10#0" 
		},
    "b893ffb4ffc7b8df65d95369875b3ab4a911e1689e302968e155229e": { 
			outRef: "a6860571a00c9b58bcbbb28805d863f538a2ba5db576e99ff78c9b8a92f22f65#0" 
		},
  }

  const refUtxos = await lucid.utxosByOutRef(Object.values(refUtxoMap).map(({outRef}) => { 
    const [txHash, outputIndex] = outRef.split('#')
    return {
      txHash,
      outputIndex: parseInt(outputIndex)
    }
  }))

  const refObjects = Object.values(refUtxoMap)
  refUtxos.map((refUtxo, ix) => {
    if (refObjects[ix]?.outRef !== `${refUtxo.txHash}#${refUtxo.outputIndex}`)
      throw new Error("Ref UTxO lookups unexpected out of order?")

    refObjects[ix].utxo = refUtxo
  })

  const multisig: L.NativeScript = {
      "type": "all",
      "scripts": [
          {
              "type": "any",
              "scripts": [
                  {
                      "type": "sig",
                      "keyHash": "d37f3a055c14f3cf4b96cf7130de771b9bf61db58c41a65c33836dad"
                  },
                  {
                      "type": "sig",
                      "keyHash": "108f896668d7901f9fe6e8942318d0b01ee6ec41915b7e164c637309"
                  },
                  {
                      "type": "sig",
                      "keyHash": "d1dd15bd37d6d0c149911eb900538f94d0771b06e92d18c486ecf58c"
                  }
              ]
          },
          {
              "type": "atLeast",
              "scripts": [
                  {
                      "type": "sig",
                      "keyHash": "83f4489857b7347d84c17cd1a45e47f5453069d2e70fe57d85738402"
                  },
                  {
                      "type": "sig",
                      "keyHash": "b426f7ca3364ab1154bdd64b639f46c29df075e6331a4b1b458f6dc9"
                  },
                  {
                      "type": "sig",
                      "keyHash": "211adad386a434b95a2341d2473642ca909938ba2ac80025ccd438a4"
                  },
                  {
                      "type": "sig",
                      "keyHash": "ae940dcd7d432dec8157957cfe9f6d7611592da0940d5d97d8a1d399"
                  },
                  {
                      "type": "sig",
                      "keyHash": "7de1bb8013ee14c1735351c6877a895c66beced65b0ad33160f8c081"
                  },
                  {
                      "type": "sig",
                      "keyHash": "5629a3f212c59cd622b1669cd4f79cb09bb22438f37725b66338c42e"
                  },
                  {
                      "type": "sig",
                      "keyHash": "1aa680e1ee852b3b09d525fb6550d9577fefa8411667c3d65888de1f"
                  }
              ],
              "required": 3
          }
      ]
  }
  const multisigScript = utils.nativeScriptFromJson(multisig)
  const multisigAddress = utils.credentialToAddress(
    {
      type: 'Script',
      hash: utils.validatorToScriptHash(multisigScript)
    },
    {
      type: 'Script',
      hash: '35bfb6f4d4ba9e70f30f931f43c4ccbb8b06e139e1f403594a67838d'
    }
  )

  const newTx = (...scripts: Validator[]) => {
    const tx = lucid.newTx()
    for (const script of scripts)
      attachScriptOrRef(tx, script)
    return tx
  }

  const seedUtxoToMintRedeemer = (
    seedUtxo: L.UTxO
  ): MintNft => {
    return {
        kind: 'MintNft',
        txOutRef: {
          kind: 'TxOutRef',
          txId: { kind: 'TxId', id: seedUtxo.txHash },
          txIdx: BigInt(seedUtxo.outputIndex)
        }
      }
  }

  const utxoToTokenName = (
    seedUtxo: L.UTxO
  ) => {
    const mintRedeemer = seedUtxoToMintRedeemer(seedUtxo)
    return Buffer.from(C.hash_blake2b256(new Uint8Array(Buffer.from(Data.to(toPlutusData(mintRedeemer.txOutRef)), 'hex')))).toString('hex')
  }

  const newTokenName = async (
  ): Promise<{ newTokenName: string, seedUtxo: L.UTxO }> => {
    const assets = { lovelace: 1_000_000n }
    const address = seedMaster
    const txHash =
      await newTx()
        .payToAddress(address, assets)
        .complete()
        .then(tx => tx.sign().complete())
        .then(tx => tx.submit())
    await lucid.awaitTx(txHash)
    const [seedUtxo] = await lucid.utxosByOutRef([{ txHash, outputIndex: 0 }])
    return { newTokenName: utxoToTokenName(seedUtxo), seedUtxo }
  }

  const attachScriptOrRef = async (tx: Tx, validator: Validator) => {
    if (validator.hash in refUtxoMap) {
      const utxo = refUtxoMap[validator.hash].utxo
      if (utxo)
        tx.readFrom([utxo])
    } else {
      attachScriptOrRef(tx, validator)
    }
  }

  const getCurrentNow = (): number => {
    return Date.now()
  }

  const mintId = async (
    validator: Validator,
    datum: Data,
    seedUtxo?: L.UTxO,
    refScript?: string,
    attachScript: boolean = true
  ): Promise<Tx> => {
    if (!seedUtxo) {
      const result = await newTokenName()
      seedUtxo = result.seedUtxo
    }
    const tokenName = utxoToTokenName(seedUtxo)
    const mintRedeemer = seedUtxoToMintRedeemer(seedUtxo)
    const tx = newTx()
      .collectFrom([seedUtxo])
      .mintAssets(
        { [validator.hash + tokenName]: 1n },
        Data.to(toPlutusData(mintRedeemer))
      )
      .payToAddressWithData(
        validator.mkAddress(),
        {
          inline: Data.to(datum),
          scriptRef: refScript ? {
            script: refScript,
            type: 'PlutusV2'
          } : undefined
        },
        { [validator.hash + tokenName]: 1n }
      )

      if (attachScript)
        attachScriptOrRef(tx, validator)

      return tx
  }

  const burnId = async (
    validator: Validator,
    datum?: Data,
  ): Promise<Tx | undefined> => {
    const mintRedeemer = { kind: 'BurnNft' }
    const whitelistUtxos = await lucid.utxosAt(validator.mkAddress())
    const burnedUtxos =
      whitelistUtxos.filter(utxo => (datum === undefined) || (utxo.datum === Data.to(datum)))
    const burnedTokens = burnedUtxos.flatMap(utxo => {
      const id = Object.entries(utxo.assets).find(([unit, _quantity]) => unit.startsWith(validator.hash))
      if (id !== undefined)
        return [[id[0], -1n]]
      else
        return []
    })
    if (burnedTokens.length === 0)
      return undefined
    return newTx(validator)
      .collectFrom(burnedUtxos, Data.to(toWrappedData(mintRedeemer)))
      .mintAssets(
        Object.fromEntries(burnedTokens),
        Data.to(toPlutusData(mintRedeemer))
      )
  }

  const utxoDatum = async(utxo: L.UTxO): Promise<L.Data | null> => {
    const datum = 
      utxo.datum
        ? utxo.datum
        : utxo.datumHash
          ? await lucid.provider.getDatum(utxo.datumHash!)
          : null
    return datum ? Data.from(datum) : null
  }

  const forceUtxoDatum = async(utxo: L.UTxO): Promise<L.Data> => {
    return (await utxoDatum(utxo))!
  }

  const referenceWhitelist = async(whitelist: Validator, hash?: string): Promise<Tx> => {
    const whitelistUtxos = await lucid.utxosAt(whitelist.mkAddress())
    return newTx()
      .readFrom(whitelistUtxos.filter(utxo => !hash || utxo.datum === Data.to(hash)))
  }

  const referenceIdWhitelist = async(whitelist: Validator, id: AssetClass): Promise<Tx> => {
    const whitelistUtxos = await lucid.utxosAt(whitelist.mkAddress())
    return newTx()
      .readFrom(whitelistUtxos.filter(utxo => utxo.datum === Data.to(toPlutusData(id))))
  }

  const includeAdminToken = async(): Promise<Tx> => {
    const soulTokenInput = await lucid.utxoByUnit(soulToken.currencySymbol + soulToken.tokenName)
    const tx = newTx()
      .collectFrom([soulTokenInput])
      .payToAddressWithData(
        soulTokenInput.address,
        {
          inline: soulTokenInput.datum ?? undefined,
          scriptRef: soulTokenInput.scriptRef ?? undefined
        },
        soulTokenInput.assets
      )

    if (soulTokenInput.address === multisigAddress)
      tx.attachSpendingValidator(multisigScript)
    
    return tx
  }

  const includeFeeClaimerToken = async(): Promise<Tx> => {
    const feeClaimerInput = await lucid.utxoByUnit(feeClaimerToken.currencySymbol + feeClaimerToken.tokenName)
    return newTx()
      .collectFrom([feeClaimerInput])
      .payToAddress(feeClaimer, feeClaimerInput.assets)
  }

  const signByController = async(): Promise<Tx> => {
    return newTx()
      .compose(await referenceWhitelist(controllerWhitelist, controllerPubKeyHash))
      .addSignerKey(controllerPubKeyHash)
  }

  const mkMintOtoken = (
    depositAmo: Validator,
    otokenRule: Validator
  ) => async (amount: bigint): Promise<Tx> => {
    return newTx(otokenPolicy)
      .mintAssets({ [otokenPolicy.hash]: amount }, Data.void())
      .withdraw(otokenRule.mkRewardAddress(), 0n, Data.void())
      .compose(await referenceWhitelist(otokenRuleWhitelist, otokenRule.hash))
      .payToContract(
        depositAmo.mkAddress(),
        { inline: Data.void() },
        { [baseAssetUnit]: amount }
      )
  }

  const mkMergeDeposits = (
    depositAmo: Validator,
  ) => async (): Promise<Tx> => {
    const cmUtxo = await getCmUtxo()
    const depositUtxos = await lucid.utxosAt(depositAmo.mkAddress())
    return newTx(depositAmo, collateralAmo)
      .collectFrom(depositUtxos, Data.to(toWrappedData(0n)))
      .withdraw(depositAmo.mkRewardAddress(), 0n, Data.void())
      .compose(await signByController())
      .collectFrom([cmUtxo], Data.to(toWrappedData({ kind: 'MergeNewDeposits' })))
      .payToContract(
        collateralAmo.mkAddress(),
        { inline: Data.to(await forceUtxoDatum(cmUtxo)) },
        {
          ...cmUtxo.assets,
          [baseAssetUnit]: (cmUtxo.assets[baseAssetUnit] ?? 0n) + depositUtxos.reduce(
            (acc, utxo) => (utxo.assets[baseAssetUnit] ?? 0n) + acc, 0n
          )
        }
      )
  }

  const mkDonate = (
    donationStrategy: Validator
  ) => async (amount: bigint): Promise<Tx> => {
    const donationUtxo =
      await lucid.utxoByUnit(donationStrategy.hash + donationStrategyTokenName)
    const previousDatum: StrategyDatum =
      fromPlutusData(strategyDatumSchema, await forceUtxoDatum(donationUtxo))
    const donationStrategyDatum: StrategyDatum = {
      kind: 'StrategyDatum',
      adaProfit: previousDatum.adaProfit + amount,
      strategyData: toData({ kind: 'DonationDatum' } as DonationDatum)
    }
    return newTx(donationStrategy)
      .collectFrom([donationUtxo], Data.to(toWrappedData({ kind: 'Donate' })))
      .payToContract(
        donationStrategy.mkAddress(),
        {
          inline: Data.to(toPlutusData(donationStrategyDatum))
        },
        {
          ...donationUtxo.assets,
          [baseAssetUnit]: (donationUtxo.assets[baseAssetUnit] ?? 0n) + amount
        }
      )
  }

  const spawnStrategy = async (
    strategy: OtokenStrategy,
    seedUtxo?: L.UTxO
  ): Promise<Tx> => {
    if (!seedUtxo) {
      const result = await newTokenName()
      seedUtxo = result.seedUtxo
    } 
    const initialDatums: { [strategy in OtokenStrategy]: any } = {
      DexStrategy: {kind: 'DexStrategyDatum', deposited: 0n} as DexStrategyDatum,
      DonationStrategy: {kind: 'False'}
    }
    const strategyTokenName = utxoToTokenName(seedUtxo)
    const strategyDatum: StrategyDatum = {
      kind: 'StrategyDatum',
      adaProfit: 0n,
      strategyData: toData(initialDatums[strategy])
    }
    const [validator, _tokenName] = strategyMap[strategy]
    const mintTx =
      await mintId(
          validator, 
          toPlutusData(strategyDatum),
          seedUtxo,
          undefined,
          false
      )

    const cmUtxo = await getCmUtxo()
    const cmRedeemer: CollateralAmoRedeemer = {
      kind: 'SpawnStrategy',
      strategy: validator.hash,
      txOutRef: {
        kind: 'TxOutRef',
        txId: {
          kind: 'TxId',
          id: seedUtxo.txHash
        },
        txIdx: BigInt(seedUtxo.outputIndex)
      }
    }
    const collateralAmoDatum: CollateralAmoDatum =
      fromPlutusData(collateralAmoDatumSchema, await forceUtxoDatum(cmUtxo))
    collateralAmoDatum.childStrategies.unshift({
      kind: 'AssetClass',
      currencySymbol: validator.hash,
      tokenName: strategyTokenName
    })
    strategyMap[strategy][1] = strategyTokenName
    return newTx(collateralAmo)
      .compose(mintTx)
      .compose(await signByController())
      .compose(await referenceWhitelist(strategyWhitelist, validator.hash))
      .collectFrom(
        [cmUtxo],
        Data.to(toWrappedData(cmRedeemer))
      )
      .payToContract(
        collateralAmo.mkAddress(),
        { inline: Data.to(toPlutusData(collateralAmoDatum)) },
        cmUtxo.assets
      )
  }

  const syncStrategy = async (
    strategyId: { policyId: string, tokenName: string },
    profit: bigint,
    deposited: bigint = profit
  ): Promise<Tx> => {
    const cmUtxo = await getCmUtxo()
    const previousDatum: CollateralAmoDatum =
      fromPlutusData(collateralAmoDatumSchema, await forceUtxoDatum(cmUtxo))
    const redeemer: CollateralAmoRedeemer = {
      kind: 'SyncStrategyCollateral',
      id: {
        kind: 'AssetClass',
        currencySymbol: strategyId.policyId,
        tokenName: strategyId.tokenName
      }
    }
    return newTx(collateralAmo)
      .compose(await signByController())
      .compose(await referenceWhitelist(strategyWhitelist, strategyId.policyId))
      .collectFrom([cmUtxo], Data.to(toWrappedData(redeemer)))
      .payToContract(
        collateralAmo.mkAddress(),
        {
          inline: Data.to(toPlutusData( {
            ...previousDatum,
            adaProfitUncommitted: previousDatum.adaProfitUncommitted + profit
          }))
        },
        {
          ...cmUtxo.assets,
          [baseAssetUnit]: cmUtxo.assets[baseAssetUnit] + deposited
        }
      )
  }

  const despawnStrategy = async (strategyId: OtokenStrategy): Promise<Tx> => {
    const [strategy, strategyTokenName] = strategyMap[strategyId]
    const cmUtxo = await getCmUtxo()
    const cmDatum: CollateralAmoDatum =
      fromPlutusData(collateralAmoDatumSchema, await forceUtxoDatum(cmUtxo))
    const strategyUtxo =
      await lucid.utxoByUnit(strategy.hash + strategyTokenName)
    const strategyDatum: StrategyDatum =
      fromPlutusData(strategyDatumSchema, await forceUtxoDatum(strategyUtxo))
    const remainingProfit = strategyDatum.adaProfit
    const redeemer: CollateralAmoRedeemer = 
      {
        kind: 'DespawnStrategy',
        id: {
          kind: 'AssetClass',
          currencySymbol: strategy.hash,
          tokenName: strategyTokenName
        }
      }

    const strategyIndex = cmDatum.childStrategies.findIndex(strategyId => 
      strategyId.currencySymbol === strategy.hash
        && strategyId.tokenName === strategyTokenName
    )
    cmDatum.childStrategies.splice(strategyIndex, 1)
    cmDatum.adaProfitUncommitted += remainingProfit
    return newTx(collateralAmo)
      .compose(await signByController())
      .compose(await referenceWhitelist(strategyWhitelist, strategy.hash))
      .collectFrom([strategyUtxo], Data.to(toWrappedData({ kind: 'CloseStrategy' })))
      .mintAssets({
        [strategy.hash + strategyTokenName]: -1n
      }, Data.to(toPlutusData({ kind: 'BurnNft' })))
      .collectFrom([cmUtxo], Data.to(toWrappedData(redeemer)))
      .payToContract(
        collateralAmo.mkAddress(),
        { inline: Data.to(toPlutusData(cmDatum)) },
        {
          ...cmUtxo.assets,
          lovelace: cmUtxo.assets.lovelace + strategyUtxo.assets.lovelace,
          [baseAssetUnit]: cmUtxo.assets[baseAssetUnit] + strategyUtxo.assets[baseAssetUnit]
        }
      )
  }

  const syncStrategy_ = async (strategy_: OtokenStrategy): Promise<Tx> => {
    const [strategy, strategyTokenName] = strategyMap[strategy_]
    const strategyUtxo =
      await lucid.utxoByUnit(strategy.hash + strategyTokenName)
    const previousDatum: StrategyDatum =
      fromPlutusData(strategyDatumSchema, await forceUtxoDatum(strategyUtxo))
    const newDatum: StrategyDatum = {
      kind: 'StrategyDatum',
      adaProfit: 0n,
      strategyData: previousDatum.strategyData
    }
    const profit = previousDatum.adaProfit
    const strategyId = {
      policyId: strategy.hash,
      tokenName: strategyTokenName
    }
    return newTx(strategy)
      .compose(await syncStrategy(strategyId, profit))
      .collectFrom([strategyUtxo], Data.to(toWrappedData({ kind: 'SyncStrategy' })))
      .payToContract(
        strategy.mkAddress(),
        { inline: Data.to(toPlutusData(newDatum)) },
        {
          ...strategyUtxo.assets,
          [baseAssetUnit]: strategyUtxo.assets[baseAssetUnit] - profit
        }
      )
  }

  const mergeStakingRate = async (): Promise<Tx> => {
    const stakingAmoUtxo = await getStakingAmoUtxo()
    const previousStakingDatum: StakingAmoDatum =
      fromPlutusData(stakingAmoDatumSchema, await forceUtxoDatum(stakingAmoUtxo))
    const previousSotokenAmount = previousStakingDatum.sotokenAmount || 1n
    const previousSotokenBacking = previousStakingDatum.sotokenBacking || 1n
    const previousOdaoSotoken = previousStakingDatum.odaoSotoken
    const adaToSotoken = (n: bigint) => n * previousSotokenAmount / previousSotokenBacking
    const odaoFee = previousStakingDatum.odaoFee
    const cmUtxo = await getCmUtxo()
    const previousCmDatum: CollateralAmoDatum =
      fromPlutusData(collateralAmoDatumSchema, await forceUtxoDatum(cmUtxo))
    const newCmDatum: CollateralAmoDatum =
      {
        ...previousCmDatum,
        adaProfitUncommitted: 0n,
      }
    const mergeAmount = previousCmDatum.adaProfitUncommitted
    const sotokenDelta = adaToSotoken(mergeAmount * odaoFee / 10000n)
    const newStakingDatum: StakingAmoDatum =  {
      ...previousStakingDatum,
      sotokenAmount: previousSotokenAmount + sotokenDelta,
      sotokenBacking: previousSotokenBacking + mergeAmount,
      odaoSotoken: previousOdaoSotoken + sotokenDelta
    }
    const cmRedeemer: CollateralAmoRedeemer = { kind: 'MergeStakingRate' }

    return newTx(stakingAmo, collateralAmo)
      .compose(await signByController())
      .collectFrom([stakingAmoUtxo], Data.to(wrapRedeemer(0n)))
      .payToContract(
        stakingAmo.mkAddress(),
        {
          inline:
            Data.to(toPlutusData(newStakingDatum))
        },
        stakingAmoUtxo.assets
      )
      .collectFrom([cmUtxo], Data.to(toWrappedData(cmRedeemer)))
      .payToContract(
        collateralAmo.mkAddress(),
        { inline: Data.to(toPlutusData(newCmDatum)) },
        cmUtxo.assets
      )
  }

  const claimOdaoFee = async (): Promise<Tx> => {
    const stakingAmoUtxo = await getStakingAmoUtxo()
    const previousStakingDatum: StakingAmoDatum =
      fromPlutusData(stakingAmoDatumSchema, await forceUtxoDatum(stakingAmoUtxo))
    const sotokenAmount = previousStakingDatum.sotokenAmount || 1n
    const sotokenBacking = previousStakingDatum.sotokenBacking || 1n
    const odaoSotoken = previousStakingDatum.odaoSotoken
    const newStakingDatum: StakingAmoDatum = {
      ...previousStakingDatum,
      odaoSotoken: 0n,
      sotokenAmount: previousStakingDatum.sotokenAmount - previousStakingDatum.odaoSotoken
    }
    return newTx(stakingAmo, otokenPolicy)
      .compose(await includeFeeClaimerToken())
      .compose(await referenceWhitelist(otokenRuleWhitelist, feeClaimRule.hash))
      .withdraw(feeClaimRule.mkRewardAddress(), 0n, Data.void())
      .mintAssets(
        { [otokenPolicy.hash]: odaoSotoken * sotokenBacking / sotokenAmount },
        Data.void()
      )
      .collectFrom([stakingAmoUtxo], Data.to(toWrappedData(0n)))
      .payToContract(
        stakingAmo.mkAddress(),
        { inline: Data.to(toPlutusData(newStakingDatum)) },
        stakingAmoUtxo.assets
      )
  }

  const getStakingAmoParams = async () => {
      const stakingAmoInput =
        await lucid.utxoByUnit(stakingAmo.hash + stakingAmoTokenName)
      const {
        kind,
        odaoSotoken,
        sotokenAmount,
        sotokenBacking,
        ...params
      } = fromPlutusData(stakingAmoDatumSchema, Data.from(stakingAmoInput.datum!))
      return params
    }

  const setStakingAmoParam = async <K extends keyof StakingAmoDatum>
    (param: K, value: StakingAmoDatum[K]): Promise<Tx> => {
      const stakingAmoInput =
        await lucid.utxoByUnit(stakingAmo.hash + stakingAmoTokenName)
      const previousDatum: StakingAmoDatum =
        fromPlutusData(stakingAmoDatumSchema, await forceUtxoDatum(stakingAmoInput))
      const newDatum: StakingAmoDatum = {
        ...previousDatum,
        [param]: value
      }
      return newTx(stakingAmo)
        .collectFrom([stakingAmoInput], Data.to(wrapRedeemer(0n)))
        .payToAddressWithData(
          stakingAmo.mkAddress(),
          { inline: Data.to(toPlutusData(newDatum)) },
          stakingAmoInput.assets
        )
    }

  const stakeOtokens = async (amount: bigint): Promise<Tx> => {
    const { paymentCredential } = utils.getAddressDetails(await lucid.wallet.address())
    const datum: BatchStakeDatum = {
      kind: 'BatchStakeDatum',
      owner: paymentCredential!.hash,
      returnAddress: {
        kind: 'Address',
        paymentCredential: {
          kind: 'PubKeyCredential',
          hash: paymentCredential!.hash
        },
        stakingCredential: {
          kind: 'Nothing'
        }
      }
    }
    return new Promise(resolve => {
      resolve(newTx()
        .payToContract(
          batchStake.mkAddress(),
          { inline: Data.to(toPlutusData(datum)) },
          {
            [otokenPolicy.hash]: amount < 0n ? 0n : amount,
            [sotokenPolicy.hash]: amount < 0n ? -amount : 0n
          }
        )
      )
    })
  }

  const sotokenRate = async(): Promise<number> => {
    const stakingAmoUtxo = await getStakingAmoUtxo()
    const stakingAmoDatum = fromPlutusData(stakingAmoDatumSchema, await forceUtxoDatum(stakingAmoUtxo))
    return Number(stakingAmoDatum.sotokenAmount || 1n) / Number(stakingAmoDatum.sotokenBacking || 1n)
  }

  const mintSotokens = async (amount?: bigint): Promise<Tx> => {
    const stakeUtxos = await lucid.utxosAt(batchStake.mkAddress({
      type: 'Key',
      hash: 'd7046f378700e6c7b9d097a1f58472d3db2d18e69d39fa7854c38cc9'
    }))
    const stakingAmoUtxo = await getStakingAmoUtxo()
    const previousDatum = fromPlutusData(stakingAmoDatumSchema, await forceUtxoDatum(stakingAmoUtxo))
    const previousSotokenAmount = previousDatum.sotokenAmount
    const previousSotokenBacking = previousDatum.sotokenBacking
    const sotokenToOtoken = (n: bigint, burnFee = 1000n) =>
      previousSotokenBacking === 0n
        ? n * burnFee / 1000n
        : n * previousSotokenBacking * burnFee / previousSotokenAmount / 1000n
    const otokenToSotoken = (n: bigint) =>
      previousSotokenBacking === 0n
        ? n
        : n * previousSotokenAmount / previousSotokenBacking

    const {
      tx: payoutTx,
      amountDelta
    } = stakeUtxos.reduce((acc, stakeUtxo) => {
      let {tx, amountDelta, outputIndex, hitLimit, done} = acc

      const otokenSent = stakeUtxo.assets[otokenPolicy.hash]
      const sotokenSent = stakeUtxo.assets[sotokenPolicy.hash]

      if (done || (hitLimit && otokenSent > 0n))
        return acc

      const datum = fromPlutusData(batchStakeDatumSchema, Data.from(stakeUtxo.datum!))
      const returnAddress = utils.credentialToAddress(
        {
          type:
            datum.returnAddress.paymentCredential.kind === 'PubKeyCredential'
              ? 'Key'
              : 'Script',
          hash: datum.returnAddress.paymentCredential.hash
        },
        datum.returnAddress.stakingCredential.kind === 'JustStakingCredential'
          && datum.returnAddress.stakingCredential.stakingCredential.kind === 'StakingHash'
          ? {
              type: datum.returnAddress.stakingCredential.stakingCredential.credential.kind === 'PubKeyCredential'
                  ? 'Key'
                  : 'Script',
              hash: datum.returnAddress.stakingCredential.stakingCredential.credential.hash
            }
          : undefined,
      )
      const returnDatum = Data.to(toPlutusData(utxoToTokenName(stakeUtxo)))

      if (otokenSent > 0n) {
        const sotokenRequested = otokenToSotoken(otokenSent)
        const sotokenRemaining =
          previousDatum.sotokenLimit - previousDatum.sotokenAmount
        const sotokenAmount =
          sotokenRemaining < sotokenRequested
            ? sotokenRemaining
            : sotokenRequested
        hitLimit ||= sotokenAmount < sotokenRequested

        const otokenAmount = sotokenToOtoken(sotokenAmount)
        const otokenChange = otokenSent - otokenAmount

        amountDelta += sotokenAmount
        const redeemer: BatchStakeRedeemer = {
          kind: 'DigestStake',
          returnIndex: outputIndex,
          continuingOrderIndex: 
            hitLimit
              ? { kind: 'JustBigInt', value: outputIndex + 1n }
              : { kind: 'Nothing' }
        }

        attachScriptOrRef(tx, batchStake)
        tx.collectFrom([stakeUtxo], Data.to(toPlutusData(redeemer)))
          .mintAssets({
            [otokenPolicy.hash]: -otokenAmount
          }, Data.void())
          .mintAssets(
            { [sotokenPolicy.hash]: sotokenAmount },
            Data.to([previousSotokenBacking || 1n, previousSotokenAmount || 1n])
          )
          .payToAddressWithData(
            returnAddress,
            { inline: returnDatum },
            { [sotokenPolicy.hash]: sotokenAmount }
          )

        if (hitLimit) {
          console.debug(otokenChange)
          tx.payToAddressWithData(
            stakeUtxo.address,
            { inline: stakeUtxo.datum! },
            { [otokenPolicy.hash]: otokenChange }
          )
        }
      } else if (sotokenSent > 0n) {
        const otokenAmount = sotokenToOtoken(sotokenSent, 999n)
        const sotokenAmount = sotokenSent

        amountDelta -= sotokenAmount
        const redeemer: BatchStakeRedeemer = {
          kind: 'DigestStake',
          returnIndex: outputIndex,
          continuingOrderIndex: { kind: 'Nothing' }
        }

        attachScriptOrRef(tx, batchStake)
        tx.collectFrom([stakeUtxo], Data.to(toPlutusData(redeemer)))
          .mintAssets({
            [otokenPolicy.hash]: otokenAmount
          }, Data.void())
          .mintAssets(
            { [sotokenPolicy.hash]: -sotokenAmount },
            Data.to([previousSotokenBacking, previousSotokenAmount])
          )
          .payToAddressWithData(
            returnAddress,
            { inline: returnDatum },
            { [otokenPolicy.hash]: otokenAmount }
          )
      }
      return {
        tx,
        amountDelta,
        outputIndex: outputIndex + 1n,
        hitLimit,
        done: true
      }
    }, {
      tx: newTx(),
      amountDelta: 0n,
      outputIndex: 0n,
      hitLimit: previousDatum.sotokenAmount >= previousDatum.sotokenLimit,
      done: false
    })

    const singleMintTx =
      amount === undefined
        ? newTx()
        : newTx()
            .mintAssets({
              [otokenPolicy.hash]: sotokenToOtoken(-amount) * (amount < 0n ? 999n : 1000n) / 1000n
            }, Data.void())
            .mintAssets(
              { [sotokenPolicy.hash]: amount },
              Data.to([previousSotokenBacking || 1n, previousSotokenAmount || 1n])
            )

    if (!amount && stakeUtxos.length === 0) {
      return newTx()
    }

    const delta = amount === undefined ? amountDelta : amount
    const newDatum: StakingAmoDatum = {
      ...previousDatum,
      sotokenAmount: previousSotokenAmount + delta,
      sotokenBacking: previousSotokenBacking + sotokenToOtoken(delta, delta < 0 ? 999n : 1000n)
    }
    console.debug(previousDatum, newDatum)
    return newTx(stakingAmo, sotokenPolicy, otokenPolicy)
      .compose(amount === undefined ? payoutTx : singleMintTx)
      .compose(await signByController())
      .compose(await referenceWhitelist(otokenRuleWhitelist, sotokenRule.hash))
      .compose(await referenceWhitelist(sotokenRuleWhitelist, sotokenRule.hash))
      .withdraw(sotokenRule.mkRewardAddress(), 0n, Data.void())
      .collectFrom([stakingAmoUtxo], Data.to(toWrappedData(0n)))
      .payToContract(
        stakingAmo.mkAddress(),
        {
          inline: Data.to(toPlutusData(newDatum))
        },
        stakingAmoUtxo.assets
      )
  }

  const strategyBaseAsset = async (strategy: OtokenStrategy) => {
    const [validator, tokenName] = strategyMap[strategy]
    const strategyUtxo = await lucid.utxoByUnit(validator.hash + tokenName)
    return strategyUtxo.assets[baseAssetUnit]
  }

  const fundStrategy = async (
    strategy: OtokenStrategy,
    amount: bigint
  ): Promise<Tx> => {
    const [validator, tokenName] = strategyMap[strategy]
    const strategyUtxo = await lucid.utxoByUnit(validator.hash + tokenName)
    const previousStrategyDatum = fromPlutusData(strategyDatumSchema, await forceUtxoDatum(strategyUtxo))
    const newStrategyDatum: StrategyDatum = {
      ...previousStrategyDatum,
      adaProfit: 0n
    }
    const redeemer: StrategyRedeemer = { kind: 'SyncStrategy' }
    const strategyId = {
      policyId: validator.hash,
      tokenName
    }
    return newTx()
      .compose(await syncStrategy(strategyId, previousStrategyDatum.adaProfit, -amount))
      .collectFrom([strategyUtxo], Data.to(toWrappedData(redeemer)))
      .payToContract(
        strategyUtxo.address,
        { inline: Data.to(toPlutusData(newStrategyDatum)) },
        {
          ...strategyUtxo.assets,
          [baseAssetUnit]: strategyUtxo.assets[baseAssetUnit] + amount
        }
      )
  }

  const bidForStake = async (
    amount: bigint,
    otoken_amount: bigint,
    bid: bigint,
    bidType: 'Full' | 'Partial'
  ): Promise<Tx> => {
    // just grab the key hash we already have
    const rewardAddress = await lucid.wallet.address()
    const { paymentCredential } = utils.getAddressDetails(rewardAddress!)
    const { hash: stakePkh } = paymentCredential!
    const datum: StakeAuctionBidDatum = {
      kind: 'StakeAuctionBidDatum',
      owner: stakePkh,
      stakeCredential: { kind: 'PubKeyCredential', hash: stakePkh },
      bid: bid,
      bidType: { kind: bidType },
      previousFill: { kind: 'Nothing' }
    }
    return newTx()
      .payToContract(
        stakeAuction.mkAddress(),
        { inline: Data.to(toPlutusData(datum)) },
        {
          [baseAssetUnit]: amount,
          [otokenPolicy.hash]: otoken_amount,
        }
      )
  }

  const getStakeBids = async (
  ): Promise<L.UTxO[]> => {
    const auctionUtxos = await lucid.utxosAt(stakeAuction.mkAddress())
    const bidUtxos: L.UTxO[] = []
    for (const utxo of auctionUtxos) {
      const datum = await forceUtxoDatum(utxo)
      if (datum instanceof L.Constr && datum.index === 0)
        bidUtxos.push(utxo)
    }
    return bidUtxos
  }

  const stakeBidDatum = (utxo: L.UTxO): StakeAuctionBidDatum => {
    return fromPlutusData(stakeAuctionBidDatumSchema, Data.from(utxo.datum!))
  }

  const lockStakes = async (
  ): Promise<Tx> => {
    const bidUtxos = await getStakeBids()
    const strategyUtxo = await lucid.utxoByUnit(stakeAuctionStrategy.hash + stakeAuctionStrategyTokenName)
    const previousStrategyDatum: StrategyDatum = fromPlutusData(strategyDatumSchema, await forceUtxoDatum(strategyUtxo))
    
    const now = getCurrentNow()
    const epoch = (BigInt(now) - epochBoundary) / epochLength

    const buffer =
      baseAssetUnit === ""
        ? 2_000_000n
        : 0n
    const available = strategyUtxo.assets[baseAssetUnit] - buffer
    const {builder, locked, baseAssetTake, otokenTake} =
      bidUtxos
        .sort((a, b) => {
          return Number(stakeBidDatum(b).bid - stakeBidDatum(a).bid)
        })
        .reduce((
          ctx: {
            builder: Tx,
            outputIndex: bigint,
            baseAssetTake: bigint,
            otokenTake: bigint,
            locked: bigint,
          },
          bidUtxo
        ) => {
          const previousBidDatum = stakeBidDatum(bidUtxo)
          const newBidDatum: StakeAuctionBidDatum = {
            ...previousBidDatum,
            previousFill: {
              kind: 'JustTxOutRef',
              txOutRef: {
                kind: 'TxOutRef',
                txId: { kind: 'TxId', id: bidUtxo.txHash },
                txIdx: BigInt(bidUtxo.outputIndex)
              }
            }
          }
          const bid = previousBidDatum.bid
          const bidAssets = bidUtxo.assets[baseAssetUnit] + bidUtxo.assets[otokenPolicy.hash]
          const maxSize = bidAssets * 73n * 1000n / bid
          const preSize = 
            ctx.locked + maxSize > available
              ? available - ctx.locked
              : maxSize
          const take = bidAssets * preSize / maxSize
          const otokenTake = minBigInt(take, bidUtxo.assets[otokenPolicy.hash])
          const otokenChange = bidUtxo.assets[otokenPolicy.hash] - otokenTake
          let baseAssetTake = maxBigInt(take - otokenTake, 0n)
          let baseAssetChange = bidUtxo.assets[baseAssetUnit] - baseAssetTake
          if (baseAssetUnit === "lovelace" && baseAssetChange <= 2_000_000n) {
            baseAssetChange = 2_000_000n
            baseAssetTake = bidUtxo.assets[baseAssetUnit] - baseAssetChange
          }
          const size = (otokenTake + baseAssetTake) * 73n * 1000n / bid
          const tx = newTx()
          const fillType: 'Full' | 'Partial' =
            size < maxSize
              ? 'Partial'
              : 'Full'

          if ((fillType === 'Partial' && previousBidDatum.bidType.kind === 'Full') || size < 10_000_000n)
            return ctx

          const redeemer: StakeAuctionRedeemer = {
            kind: 'MatchOrder',
            fillType: { kind: fillType },
            lockOutputIndex: ctx.outputIndex
          }
  
          tx.collectFrom([bidUtxo], Data.to(toWrappedData(redeemer)))
          tx.payToContract(
            stakeAuction.mkAddress({
              type:
                previousBidDatum.stakeCredential.kind === 'PubKeyCredential'
                  ? 'Key'
                  : 'Script',
              hash: previousBidDatum.stakeCredential.hash
            }),
            { inline: Data.to(toPlutusData({
                kind: 'StakeAuctionLockDatum',
                epoch
              }))
            },
            {
              [baseAssetUnit]: size
            }
          )
          if (fillType === 'Partial')
            tx.payToContract(
              stakeAuction.mkAddress(),
              { inline: Data.to(toPlutusData(newBidDatum)) },
              {
                [baseAssetUnit]: baseAssetChange,
                [otokenPolicy.hash]: otokenChange
              }
            )

          ctx.otokenTake += otokenTake
          ctx.baseAssetTake += baseAssetTake
          ctx.outputIndex += 1n + (fillType === 'Full' ? 0n : 1n)
          ctx.locked += size
          ctx.builder.compose(tx)
          return ctx
        },
        {
          builder: newTx(),
          outputIndex: 0n,
          baseAssetTake: 0n,
          otokenTake: 0n,
          locked: 0n,
        })

    const newStrategyDatum: StrategyDatum = {
      ...previousStrategyDatum,
      adaProfit: previousStrategyDatum.adaProfit + baseAssetTake + otokenTake,
      strategyData: previousStrategyDatum.strategyData
    }
    const stakeAuctionRedeemer: StakeAuctionParams = {
      kind: 'StakeAuctionParams',
      strategy: stakeAuctionStrategyId,
      profit: baseAssetTake + otokenTake,
      diff: new Map([
        [baseAsset.currencySymbol, new Map([[baseAsset.tokenName, (locked - baseAssetTake)]])],
        [otokenPolicy.hash, new Map([["", -otokenTake]])]
      ]),
    }
    return newTx(stakeAuctionStrategy, stakeAuction)
      .validFrom(now)
      .validTo(now + 3600_000)
      .compose(builder)
      .compose(await referenceIdWhitelist(stakeAuctionWhitelist, stakeAuctionStrategyId))
      .withdraw(
        stakeAuction.mkRewardAddress(),
        0n,
        Data.to(toPlutusData(stakeAuctionRedeemer))
      )
      .collectFrom([strategyUtxo], Data.to(toWrappedData({ kind: 'MoveStakes' })))
      .payToContract(
        stakeAuctionStrategy.mkAddress(),
        { inline: Data.to(toPlutusData(newStrategyDatum)) },
        {
          ...strategyUtxo.assets,
          [baseAssetUnit]: strategyUtxo.assets[baseAssetUnit] - (locked - baseAssetTake),
          [otokenPolicy.hash]: otokenTake
        }
      )
  }

  const cancelBid = async (txOutRef: string) => {
    const [txHash, outputIndex] = txOutRef.split('#')
    const bidUtxo = (await lucid.utxosByOutRef([{ txHash, outputIndex: parseInt(outputIndex) }]))[0]
    const bidDatum: StakeAuctionBidDatum = fromPlutusData(stakeAuctionBidDatumSchema, await forceUtxoDatum(bidUtxo))
    
    const now = getCurrentNow()

    const redeemer: StakeAuctionRedeemer = {
      kind: 'CancelOrder',
    }

    return newTx(stakeAuction)
      .addSignerKey(bidDatum.owner)
      .collectFrom([bidUtxo], Data.to(toWrappedData(redeemer)))
      .validFrom(now)
      .validTo(now + 3600_000)
  }

  const getLockedStakes = async (
  ): Promise<L.UTxO[]> => {
    const auctionUtxos = await lucid.utxosAt({ type: 'Script', hash: stakeAuction.hash })
    const lockUtxos: L.UTxO[] = []
    for (const utxo of auctionUtxos) {
      const datum = await forceUtxoDatum(utxo)
      if (datum instanceof L.Constr && datum.index === 1)
        lockUtxos.push(utxo)
    }
    return lockUtxos
  }

  const unlockStakes = async (
  ): Promise<Tx> => {
    const lockUtxos = await getLockedStakes()
    const strategyUtxo = await lucid.utxoByUnit(stakeAuctionStrategy.hash + stakeAuctionStrategyTokenName)
    const now = getCurrentNow()
    const unlockedAmount = lockUtxos.reduce((acc, utxo) => acc + utxo.assets[baseAssetUnit], 0n)
    const previousStrategyDatum: StrategyDatum = fromPlutusData(strategyDatumSchema, await forceUtxoDatum(strategyUtxo))
    const stakeAuctionRedeemer: StakeAuctionParams = {
      kind: 'StakeAuctionParams',
      strategy: stakeAuctionStrategyId,
      profit: 0n,
      diff: new Map(
        unlockedAmount > 0n
          ? [
              [baseAsset.currencySymbol, new Map([[baseAsset.tokenName, -unlockedAmount]])],
            ]
          : []
      ),
    }
    return newTx(stakeAuction, stakeAuctionStrategy, stakeAuction)
      .compose(await referenceIdWhitelist(stakeAuctionWhitelist, stakeAuctionStrategyId))
      .withdraw(
        stakeAuction.mkRewardAddress(),
        0n,
        Data.to(toPlutusData(stakeAuctionRedeemer))
      )
      .collectFrom([strategyUtxo], Data.to(toWrappedData({ kind: 'MoveStakes' })))
      .collectFrom(lockUtxos, Data.to(toWrappedData({ kind: 'UnlockStake' })))
      .validFrom(now)
      .validTo(now + 3600_000)
      .payToContract(
        stakeAuctionStrategy.mkAddress(),
        { inline: Data.to(toPlutusData(previousStrategyDatum)) },
        {
          ...strategyUtxo.assets,
          [baseAssetUnit]: strategyUtxo.assets[baseAssetUnit] + unlockedAmount
        }
      )
  }

  const mkGetId = (idUnit: string) => () => lucid.utxoByUnit(idUnit)

  //////////////////////////////////////////////////////////////////////////////
  // ACTUAL TRANSACTIONS BELOW
  const collateralAmoId: AssetClass = {
    kind: 'AssetClass',
    currencySymbol: collateralAmo.hash,
    tokenName: collateralAmoTokenName
  }
  const depositAmo = loadValidator(
    OadaScripts,
    'deposit_amo.spend',
    [toPlutusData(baseAsset), toPlutusData(collateralAmoId)]
  )
  const stakingAmo = loadValidator(
    OadaScripts,
    'staking_amo.spend',
    [soulTokenData, toPlutusData(collateralAmoId), controllerWhitelist.hash]
  )
  const donationStrategy = loadValidator(
    OadaScripts,
    'donation_strategy.spend',
    [
      controllerWhitelist.hash,
      toPlutusData(baseAsset),
      toPlutusData(collateralAmoId)
    ]
  )
  const otokenRule = loadValidator(
    OadaScripts,
    'otoken_rule.withdraw',
    [toPlutusData(baseAsset), otokenPolicy.hash, depositAmo.hash, minimumDepositLovelace]
  )
  const stakeAuction = loadValidator(
    OadaScripts,
    'stake_auction.withdraw',
    [otokenPolicy.hash, toPlutusData(baseAsset), stakeAuctionWhitelist.hash]
  )
  const stakeAuctionStrategy = loadValidator(
    OadaScripts,
    'stake_auction_strategy.spend',
    [
      controllerWhitelist.hash,
      toPlutusData(collateralAmoId),
      stakeAuction.hash,
      otokenPolicy.hash
    ]
  )
  const stakeAuctionStrategyId: AssetClass = {
    kind: 'AssetClass',
    currencySymbol: stakeAuctionStrategy.hash,
    tokenName: stakeAuctionStrategyTokenName
  }
  const dexStrategy = loadValidator(
    OadaScripts,
    'dex_strategy.spend',
    [
      controllerWhitelist.hash,
      toPlutusData(collateralAmoId),
      toPlutusData(baseAsset),
      otokenPolicy.hash,
      stakeAuction.hash,
      toPlutusData(stablePoolNft),
      toPlutusData(stablePoolLpToken),
      stablePoolAssetOrder,
      toPlutusData(stablePoolDepositGuard)
    ]
  )

  const mintOtoken = mkMintOtoken(depositAmo, otokenRule)
  const mergeDeposits = mkMergeDeposits(depositAmo)
  const donate = mkDonate(donationStrategy)

  const getCmUtxo = mkGetId(collateralAmo.hash + collateralAmoTokenName)
  const getStakingAmoUtxo = mkGetId(stakingAmo.hash + stakingAmoTokenName)

  const stakingAmoId: AssetClass = {
    kind: 'AssetClass',
    currencySymbol: stakingAmo.hash,
    tokenName: stakingAmoTokenName
  }
  const batchStake = loadValidator(
    OadaScripts,
    'batch_stake.spend',
    [otokenPolicy.hash, toPlutusData(stakingAmoId)]
  )
  const sotokenRule = loadValidator(
    OadaScripts,
    'sotoken_rule.withdraw',
    [otokenPolicy.hash, toPlutusData(stakingAmoId)]
  )
  const feeClaimRule = loadValidator(
    OadaScripts,
    'fee_claim_rule.withdraw',
    [otokenPolicy.hash, toPlutusData(stakingAmoId)]
  )

  const strategyMap: { [strategy in OtokenStrategy]: [Validator, string] } = {
    DonationStrategy: [donationStrategy, donationStrategyTokenName],
    DexStrategy: [dexStrategy, dexStrategyTokenName]
  }

  const knownStrategyIds = (): { name: OtokenStrategy, value: string }[] => {
    return Object.entries(strategyMap).map(strategy => {
      return {
        name: strategy[0] as OtokenStrategy,
        value: `${strategy[1][0].hash}.${strategy[1][1]}`
      }
    })
  }

  const knownStrategyScripts = (): { name: OtokenStrategy, value: string }[] => {
    return Object.entries(strategyMap).map(strategy => {
      return {
        name: strategy[0] as OtokenStrategy,
        value: strategy[1][0].validator.script
      }
    })
  }

  const whitelistMap: { [whitelist in OtokenWhitelistPurpose]: Validator } = {
    [OtokenWhitelistPurpose.OTOKEN_RULE]: otokenRuleWhitelist,
    [OtokenWhitelistPurpose.SOTOKEN_RULE]: sotokenRuleWhitelist,
    [OtokenWhitelistPurpose.CONTROLLER]: controllerWhitelist,
    [OtokenWhitelistPurpose.STRATEGY]: strategyWhitelist,
    [OtokenWhitelistPurpose.STAKE_AUCTION]: stakeAuctionWhitelist,
  }

  const whitelistAdd = async (whitelist: OtokenWhitelistPurpose, datum: any, refScript?: string) => {
    const validator = whitelistMap[whitelist]
    const utxos = await lucid.wallet.getUtxos()
    return mintId(validator, toPlutusData(datum), utxos[0], refScript)
  }

  const whitelistRemove = async (whitelist: OtokenWhitelistPurpose, datum?: any) => {
    const validator = whitelistMap[whitelist]
    return burnId(validator, datum && toPlutusData(datum))
  }
  
  const whitelistKeys = async(whitelist: OtokenWhitelistPurpose): Promise<string[]> => {
    const validator = whitelistMap[whitelist]
    const utxos = await lucid.utxosAt(validator.mkAddress())
    return utxos.map(utxo => Data.from(utxo.datum!))
  }

  const whitelistIds = async(whitelist: OtokenWhitelistPurpose): Promise<AssetClass[]> => {
    const validator = whitelistMap[whitelist]
    const utxos = await lucid.utxosAt(validator.mkAddress())
    return utxos.map(utxo => fromPlutusData(assetClassSchema, Data.from(utxo.datum!)))
  }

  const dexStrategyRule = loadValidator(
    OadaScripts,
    'dex_strategy.withdraw',
    [
      toPlutusData({
        kind: 'AssetClass',
        currencySymbol: strategyMap['DexStrategy'][0].hash,
        tokenName: strategyMap['DexStrategy'][1]
      })
    ]
  )

  const otokenRuleMap: { [rule in OtokenRule]: Validator } = {
    OtokenRule: otokenRule,
    sOtokenRule: sotokenRule,
    FeeClaimRule: feeClaimRule,
    DexStrategyRule: dexStrategyRule
  }

  const knownOtokenRules = (): { name: OtokenRule, value: string }[] => {
    return Object.entries(otokenRuleMap).map(rule => {
      return {
        name: rule[0] as OtokenRule,
        value: rule[1].validator.script
      }
    })
  }

  const cmAmoBaseAsset = async() => {
    const utxo = await getCmUtxo()
    return utxo.assets[baseAssetUnit]
  }

  const useController = async(pubKeyHash: string) => {
    controllerPubKeyHash = pubKeyHash
  }

  const currentController = () => {
    return controllerPubKeyHash
  }

  return {
    epochBoundary,
    epochLength,

    sotokenRate,
    getStakingAmoParams,
    setStakingAmoParam,
    mintOtoken,
    mintSotokens,
    mergeDeposits,
    syncStrategy: syncStrategy_,
    mergeStakingRate,
    donate,
    claimOdaoFee,
    fundStrategy,
    bidForStake,
    cancelBid,
    stakeOtokens,
    lockStakes,
    unlockStakes,
    spawnStrategy,
    despawnStrategy,
    scriptUtils,
    getLockedStakes,
    getStakeBids,
    stakeBidDatum,
    strategyBaseAsset,
    cmAmoBaseAsset,
    useController,
    includeAdminToken,
    
    whitelistAdd,
    whitelistRemove,
    whitelistKeys,
    whitelistIds,

    knownStrategyIds,
    knownStrategyScripts,
    knownOtokenRules,

    currentController
  }
}

export type OtokenSystem = Awaited<ReturnType<typeof initOtoken>>
