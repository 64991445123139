import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState, Services, ThunkAPI } from "../index";
import { UITypes } from "../../types";
import {lucid} from "../hooks";
import {Wallet} from "../../bond/store-types";
import {Result} from "../../bond/result";
import {Utils, WalletApi} from "lucid-cardano";
import * as Lucid from 'lucid-cardano'
import {pruneAndMostRecentUtxos} from "../../bond/actions";
import * as St from "../../bond/store-types";
import {lucidUtxoToUtxo} from "../../bond/getters/ui";
import {isNullOrErr, selectOwnNftCurrencySymbol, selectJboOwnNftPolicyId, selectPoolCurrencySymbols, setRewardAccounts} from "../../bond/getters/slice"
import Big from "big.js";
import { selectBondTokenCurrencySymbols } from "../../bond/getters/slice";
import Ada, {
  Networks,
  Network,
  TransactionSigningMode,
  HARDENED
} from '@cardano-foundation/ledgerjs-hw-app-cardano';
import * as HW from '@cardano-foundation/ledgerjs-hw-app-cardano';
import {
  decodeTx,
  Int,
  transformTx,
  Uint,
} from 'cardano-hw-interop-lib';
import * as VL from 'cardano-hw-interop-lib';

import * as Config from "../../config.local";
// don't know the right way to do this
import {
  bech32_encodeAddress,
  bech32_decodeAddress
} from "@cardano-foundation/ledgerjs-hw-app-cardano/dist/utils";
import { C } from 'lucid-cardano';

import blake2b from 'blake2b';
import { network, oadaNetwork } from "../../network";
import { makeJsonRpcNotif } from "../../JsonRpc";
import { getLatestUtxosFromPersistedVirtualWalletUtxoMap } from "../../bond/wallet-stuff";
import {selectOadaLpToken} from "src/oada/actions";
const Bip32 = require('bip32-ed25519'); // needs declaration file

interface WalletState {
  wallet: UITypes.Wallets.Wallet | null,
  partialWallet: St.PartialWallet,
  showWalletSelect: boolean,
  lastTxId?: string,
  feeAddress: string | null
}

const initialState: WalletState = {
  wallet: null,
  partialWallet: {
    utxos: [],
  },
  showWalletSelect: false,
  lastTxId: undefined,
  feeAddress: null
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    // setWallet: (state, action: PayloadAction<UITypes.Wallets.Wallet | null>) => {
    //   state.wallet = action.payload;
    // },
    setWalletFeeAddress: (state, action: PayloadAction<string | null>) => {
      state.feeAddress = action.payload;
    },
    toggleShowWalletSelect: (state) => {
      state.showWalletSelect = !state.showWalletSelect;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateWalletUtxosThunk.fulfilled, (state, action) => {
        if (state.wallet === null || action.payload === null) {
          state.partialWallet = { utxos: [] }
          return;
        }
        const utxos = action.payload

        const utxoRefs = new Set(utxos.map(utxoToUtxoRefString))
        // console.log('UTXOREFS')
        // console.log(!(state.partialWallet.utxos.every(u => utxoRefs.has(utxoToUtxoRefString(u)))
        //       && state.partialWallet.utxos.length === utxoRefs.size))
        if (!(state.partialWallet.utxos.every(u => utxoRefs.has(utxoToUtxoRefString(u)))
              && state.partialWallet.utxos.length === utxoRefs.size)) {
          console.log('updateWalletUtxosThunk changed utxos')
          state.partialWallet = { utxos }
        }
        console.log("updateWalletUtxosThunk fulfilled")
      })
      .addCase(setWalletByProvider.fulfilled, (state, action) => {
        state.wallet = action.payload
        state.showWalletSelect = false
        console.log('setWalletByProvider fulfilled')
      })
      .addCase(setWalletByLedgerApp.fulfilled, (state, action) => {
        state.wallet = action.payload
        state.showWalletSelect = false
        console.log('setWalletByProvider fulfilled')
      })
      .addCase(disconnectWalletThunk.fulfilled, (state, _action) => {
        if (state.wallet !== null) {
          state.wallet = null
          state.partialWallet.utxos = []
        }
      })
  }
})

export const { setWalletFeeAddress, toggleShowWalletSelect } = walletSlice.actions;
export default walletSlice.reducer

/** 
 * Global variable 
 * Gets updated only by selectWalletByProvider async thunk
 * TODO: Maybe we can just put this in the store.
 */
export const walletApiByProviderByAddress: { [name: string]: { [address: string]: WalletApi } } = {}

type DisconnectWalletParams = {
  ws: WebSocket | null,
}

export const disconnectWalletThunk = createAsyncThunk<
  void,
  DisconnectWalletParams,
  {
    state: RootState,
    extra: Services,
  }
>(
  'wallet/disconnectWalletThunk',
  async (params: DisconnectWalletParams, thunkApi: ThunkAPI) => {
    const dispatch = thunkApi.dispatch
    const wallet = thunkApi.getState().wallet.wallet
    const walletProviderName = wallet?.provider
    if (walletProviderName !== undefined) {
      localStorage.removeItem('walletProviderName')
      const walletAddress = wallet?.address
      if (walletAddress !== undefined) {
        const address = await lucid.wallet.address()
        sendDisconnectWalletWsNotif(params.ws, address)
        dispatch(setRewardAccounts([]))
      }
    }
  }
)

// TODO: we don't handle/catch possible errors
export const updateWalletUtxosThunk = createAsyncThunk<
  St.Utxo[],
  unknown,
  {
    state: RootState,
    extra: Services,
  }
>(
  'wallet/updateWalletUtxosThunk',
  async (_: unknown, thunkAPI: ThunkAPI) => {
    // const wallet = thunkAPI.getState().wallet.wallet;

    const utxos = await lucid.wallet.getUtxos()
    console.log('WALLET UTXOS BEFORE PRUNING')
    console.log(utxos)
    // virtual wallet stuff
    const mostRecentUtxos = pruneAndMostRecentUtxos(utxos)
    // yes we currently have 2 virtual utxo systems...
    // TODO: make it so we have 1
    const mostRecentUtxos2 = getLatestUtxosFromPersistedVirtualWalletUtxoMap(mostRecentUtxos)

    console.log('WALLET UTXOS AFTER PRUNING')
    console.log(mostRecentUtxos2)
    
    // console.log("walletPoolTokenNameAssets")
    // console.log(walletPoolTokenNameAssets['c714bbbf65973f0a73b0d880cf7bfa726da0e49146cf4eaa4dfedd172d431172']?.toNumber())
    console.log('mostRecentUtxos')
    // console.log(mostRecentUtxos.filter(u => Object.keys(u.assets).some(key => key.includes('c714bbbf65973f0a73b0d880cf7bfa726da0e49146cf4eaa4dfedd172d431172') ) ))
    // console.log(mostRecentUtxos)

    return mostRecentUtxos2.map(lucidUtxoToUtxo) 
  }
)

type SetWalletByProviderParams = {
  name: string,
  ws: WebSocket | null,
}

export const setWalletByProvider = createAsyncThunk<
  UITypes.Wallets.Wallet | null,
  SetWalletByProviderParams,
  {
    state: RootState,
    extra: Services,
  }
>(
  'wallet/selectByProvider', 
  async (params, thunkAPI) => {
    const walletProviderName = params.name
    const ws = params.ws
    if (thunkAPI.getState().wallet.wallet !== null) return thunkAPI.getState().wallet.wallet
    const { walletApiProvider } = thunkAPI.extra

    const walletApi = await walletApiProvider.getWalletApi(walletProviderName)

    lucid.selectWallet(walletApi)

    // NOTE: now lucid contains the wallet api we used before
    // and the wallet api is the raw api in the CIP (probably)
    const address = await lucid.wallet.address()
    sendWalletConnectWsNotif(ws, address)
    console.log('Um Heloo?')

    walletApiByProviderByAddress[walletProviderName] = {
      [address]: walletApi
    }

    const serializableWallet: UITypes.Wallets.Wallet = { 
      provider: walletProviderName,
      address: address,
      utxos: [],
    }
    localStorage.setItem('walletProviderName', walletProviderName)
    return serializableWallet
  }
)

export const sendWalletConnectWsNotif = (ws: WebSocket | null, address: string) => {
  sendWsWalletNotif(ws, 'ConnectWallet', address)
}

const sendDisconnectWalletWsNotif = async (ws: WebSocket | null, address: string) => {
  sendWsWalletNotif(ws, 'DisconnectWallet', address)
}

type WalletNotifMethod = 'ConnectWallet' | 'DisconnectWallet'
const sendWsWalletNotif = (ws: WebSocket | null, method: WalletNotifMethod, address: string) => {
  const notif = makeJsonRpcNotif(method, {address})
  if (ws !== null) {
    console.log('Wallet Ws Notif Send:')
    console.log(JSON.stringify(notif))

    const timer = setInterval(function() {
      console.log('WebsocketSendWalletNotif')
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(notif))
        clearInterval(timer)
        console.log(`WebsocketSendWalletNotif cleared: ${timer}`)
      }
    }, 1000)
  }
}

type SetWalletByLederAppParams = {
   adaApp: Ada, account: number, ws: WebSocket | null 
}

export const setWalletByLedgerApp = createAsyncThunk<
  UITypes.Wallets.Wallet,
  SetWalletByLederAppParams
>(
  'wallet/setWalletByLedgerApp', 
  async (params: SetWalletByLederAppParams, thunkAPI) => {
    const {adaApp, account} = params
    const walletProviderName = 'ledger'
    const walletApi = undefined; // TODO? define CIP30 interface for Ledger wallet

    const networkMap: {[name: string]: Network} = {
      'Mainnet': Networks.Mainnet,
      'Preprod': {
        networkId: 0x00,
        protocolMagic: 1
      } as Network,
      'Preview': {
        networkId: 0x00,
        protocolMagic: 2
      } as Network,
    }
    const spendingPath = [1852 + HARDENED, 1815 + HARDENED, account + HARDENED, 0, 0];
    const accountRoot = [1852 + HARDENED, 1815 + HARDENED, account + HARDENED];
    const stakingPath = [1852 + HARDENED, 1815 + HARDENED, account + HARDENED, 2, 0];
    const network: Network = networkMap[Config.cardanoNetwork];
    const xpubRoot = await
      adaApp
        .getExtendedPublicKey({ path: accountRoot })
        .then((xpub): Buffer => {
          const { publicKeyHex, chainCodeHex } = xpub;
          return Buffer.concat([Buffer.from(publicKeyHex, 'hex'), Buffer.from(chainCodeHex, 'hex')])
        })
    const derivePubKeyHex = (pathStub: Array<number>): string => {
      return pathStub.reduce((result, n) => Bip32.derivePublic(result, n), xpubRoot)
                     .slice(0,32).toString('hex')
    }
    const hashPubKeyHex = (pubKeyHex: string): string => {
      return blake2b(28).update(Buffer.from(pubKeyHex, 'hex')).digest('hex')
    }
    const deviceOwnedAddress: HW.DeviceOwnedAddress = {
        type: HW.AddressType.BASE_PAYMENT_KEY_STAKE_KEY,
        params: {
          spendingPath,
          stakingPath
        }
      }
    const deviceOwnedRewardAddress: HW.DeviceOwnedAddress = {
        type: HW.AddressType.REWARD_KEY,
        params: {
          stakingPath
        }
      }
    const getAddress = async () => {
      const {addressHex} = await adaApp.deriveAddress({
        network,
        address: deviceOwnedAddress
      })
      return bech32_encodeAddress(Buffer.from(addressHex, 'hex'))
    }
    const getRewardAddress = async () => {
      const {addressHex} = await adaApp.deriveAddress({
        network,
        address: deviceOwnedRewardAddress
      })
      return bech32_encodeAddress(Buffer.from(addressHex, 'hex'))
    }
    const stakePubKeyHex = derivePubKeyHex([2, 0])
    const spendPubKeysHex: { [stub: string]: string } = {}
    const pubKeyHashToPath: { [hash: string]: HW.BIP32Path } = {}

    const address = await getAddress()

    const serializableWallet: UITypes.Wallets.Wallet = { 
      provider: walletProviderName,
      address,
      utxos: [],
    }
    localStorage.setItem('walletProviderName', walletProviderName)
    sendWalletConnectWsNotif(params.ws, address)

    const vlToHw = {
      tokens: function <T extends Int | Uint>(token: VL.Token<T>) {
        return {
          assetNameHex: Buffer.from(token.assetName).toString('hex'),
          amount: token.amount
        }
      },
      assetGroup: function <T extends Int | Uint>(assetGroup: VL.AssetGroup<T>) {
        return {
          policyIdHex: Buffer.from(assetGroup.policyId).toString('hex'),
          tokens: assetGroup.tokens.map(vlToHw.tokens)
        }
      },
      signatory: function (signatory: VL.FixLenBuffer<28>): HW.RequiredSigner {
        const signatoryHex = signatory.toString('hex')
        const path = pubKeyHashToPath[signatoryHex]
        if (path) {
          return {
            type: HW.TxRequiredSignerType.PATH,
            path
          }
        } else {
          return {
            type: HW.TxRequiredSignerType.HASH,
            hashHex: signatoryHex
          }
        }
      },
      input: function (input: VL.TransactionInput): HW.TxInput {
        return {
          txHashHex: Buffer.from(input.transactionId).toString('hex'),
          outputIndex: Number(input.index),
          path: [1852 + HARDENED, 1815 + HARDENED, account + HARDENED, 0, 0],
        }
      },
      output: function (output: VL.TransactionOutput): HW.TxOutput {
        const outputAddressBuffer = Buffer.from(output.address)
        const outputAddressHex = outputAddressBuffer.toString('hex')
        const outputAddressBech32 = bech32_encodeAddress(outputAddressBuffer)
        // technically only accounts for m/1852'/1815'/0'/0/0, but our
        // transactions will always send change to this address
        const destination: HW.TxOutputDestination =
          address === outputAddressBech32
            ? { 
                type: HW.TxOutputDestinationType.DEVICE_OWNED,
                params: deviceOwnedAddress
              }
            : {
                type: HW.TxOutputDestinationType.THIRD_PARTY,
                params: { addressHex: outputAddressHex }
              }
        const amount = output.amount.coin
        const tokenBundle = 
          output.amount.type === VL.AmountType.WITH_MULTIASSET
            ? output.amount.multiasset.map(vlToHw.assetGroup)
            : undefined
        if (output.format === VL.TxOutputFormat.ARRAY_LEGACY) {
          return {
            format: HW.TxOutputFormat.ARRAY_LEGACY,
            destination,
            amount,
            tokenBundle,
            datumHashHex:
              output.datumHash
                ? Buffer.from(output.datumHash).toString('hex')
                : undefined
          }
        } else if (output.format === VL.TxOutputFormat.MAP_BABBAGE) {
          let datum: HW.Datum | undefined = undefined;
          if (output.datum?.type === VL.DatumType.HASH)
            datum = {
              type: HW.DatumType.HASH,
              datumHashHex: Buffer.from(output.datum.hash).toString('hex')
            }
          else if (output.datum?.type === VL.DatumType.INLINE)
            datum = {
              type: HW.DatumType.INLINE,
              datumHex: Buffer.from(output.datum.bytes).toString('hex')
            }
          return {
            format: HW.TxOutputFormat.MAP_BABBAGE,
            destination,
            amount,
            tokenBundle,
            datum,
            referenceScriptHex: output.referenceScript?.toString('hex')
          }
        } else
          throw new Error("unrecognized output type")
      },
      scriptDataHash: function (auxDataHash?: VL.FixLenBuffer<typeof VL.AUXILIARY_DATA_HASH_LENGTH>) {
        return auxDataHash && Buffer.from(auxDataHash).toString('hex')
      },
      auxiliaryData: function (auxDataHash?: VL.FixLenBuffer<typeof VL.AUXILIARY_DATA_HASH_LENGTH>): HW.TxAuxiliaryData | undefined {
        return auxDataHash && {
          type: HW.TxAuxiliaryDataType.ARBITRARY_HASH,
          params: { hashHex: Buffer.from(auxDataHash).toString('hex') }
        }
      }
    }
    
    let lastSync: any = new Date(0)
    let syncing: boolean = false
    let walletUtxos: Lucid.UTxO[] = [];
    lucid.wallet = {
      getUtxos: async () => {
        let now: any = new Date()
        if ((now - lastSync < 60000) || syncing)
          return walletUtxos;
        syncing = true
        let misses = 0;
        const gap = 4;
        const stakePubKeyHash = hashPubKeyHex(stakePubKeyHex) 
        let newWalletUtxos: Lucid.UTxO[] = []
        for (let i = 0; misses <= gap; i++) {
          const spendPubKeyHex = derivePubKeyHex([0, i])
          const changePubKeyHex = derivePubKeyHex([1, i])
          const spendPubKeyHash = hashPubKeyHex(spendPubKeyHex)
          const changePubKeyHash = hashPubKeyHex(changePubKeyHex)
          const baseHeader = Buffer.from([0x00 | network.networkId]).toString('hex')
          const enterpriseHeader = Buffer.from([0x60 | network.networkId]).toString('hex')
          const addressBuffers = [
            Buffer.from(baseHeader + spendPubKeyHash + stakePubKeyHash, 'hex'),
            Buffer.from(baseHeader + changePubKeyHash + stakePubKeyHash, 'hex'),
            Buffer.from(enterpriseHeader + spendPubKeyHash, 'hex'),
            Buffer.from(enterpriseHeader + changePubKeyHash, 'hex')
          ]
          const utxosResult = await Promise.all(addressBuffers.map(async (addressBuffer) => lucid.provider.getUtxos(bech32_encodeAddress(addressBuffer))))
          const utxos = utxosResult.reduce((a, v) => a.concat(v))
          if (0 in utxos) {
            misses = 0
            spendPubKeysHex['0/' + i] = spendPubKeyHex
            spendPubKeysHex['1/' + i] = changePubKeyHex
            newWalletUtxos = newWalletUtxos.concat(utxos)
          } else {
            ++misses
          }
        }
        lastSync = new Date()
        Object.entries(spendPubKeysHex).forEach(([stub, pubKeyHex]) => {
          const keyHash = hashPubKeyHex(pubKeyHex)
          pubKeyHashToPath[keyHash] = [1852 + HARDENED, 1815 + HARDENED, account + HARDENED].concat(stub.split('/').map(v=>parseInt(v)))
        })
        walletUtxos = newWalletUtxos
        syncing = false
        return walletUtxos
      },
      address: getAddress,
      rewardAddress: getRewardAddress,
      getUtxosCore: () => new Promise((_res, rej) => rej("getUtxosCore not implemented")),
      getDelegation: () => getRewardAddress().then(lucid.provider.getDelegation),
      signTxGeneric: async function (tx: VL.Transaction) {
        const txWitnessSet: Map<any, any> = tx.witnessSet as any
        const txBody = tx.body
        const request = {
          signingMode: TransactionSigningMode.PLUTUS_TRANSACTION,
          tx: {
            network,
            inputs: txBody.inputs.items.map(vlToHw.input),
            outputs: txBody.outputs.map(vlToHw.output),
            fee: txBody.fee?.toString(),
            validityIntervalStart: txBody.validityIntervalStart,
            ttl: txBody.ttl?.toString(),
            mint: txBody.mint?.map(vlToHw.assetGroup),
            auxiliaryData: vlToHw.auxiliaryData(txBody.auxiliaryDataHash),
            scriptDataHashHex: vlToHw.scriptDataHash(txBody.scriptDataHash),
            collateralInputs: txBody.collateralInputs?.items.map(vlToHw.input),
            includeNetworkId: txBody.networkId !== undefined,
            collateralOutput: txBody.collateralReturnOutput && vlToHw.output(txBody.collateralReturnOutput),
            totalCollateral: txBody.totalCollateral?.toString(),
            referenceInputs: txBody.referenceInputs?.items.map(vlToHw.input),
            requiredSigners: txBody.requiredSigners?.items.map(vlToHw.signatory),
            certificate: undefined, // not needed for Optim
            withdrawals: undefined, // not needed for Optim
          },
          additionalWitnessPaths: 
            walletUtxos.filter(walletUtxo =>
              txBody.inputs.items.find(input =>
                (input.transactionId.toString('hex') === walletUtxo.txHash) && (input.index === walletUtxo.outputIndex))
            ).map(walletUtxo => pubKeyHashToPath[bech32_decodeAddress(walletUtxo.address).slice(1,29).toString('hex')])
             .filter(v => v !== undefined)
        }
        const version: HW.Version = { major: 5, minor: 0, patch: 0, flags: { isDebug: false } }
        const {txHashHex, witnesses, auxiliaryDataSupplement} = await adaApp.signTransaction(request)
        txWitnessSet.set(0, witnesses.map((witness) => {
          return [
            Buffer.from(spendPubKeysHex[witness.path.slice(3).join('/')], 'hex'),
            Buffer.from(witness.witnessSignatureHex, 'hex'),
          ]
        }))
        lastSync = new Date(0)
        setTimeout(this.getUtxos, 2000)
        return {
          signedTx: tx,
          txHashHex,
          witnesses
        }
      },
      signTx: async function (tx: any) {
        const transformedTx: VL.Transaction = transformTx(decodeTx(Buffer.from(tx.to_bytes())))
        const {witnesses} = await this.signTxGeneric(tx)
        const vkeyWitnesses = C.Vkeywitnesses.new()
        const witnessSet = tx.witness_set()

        witnesses.forEach(async (witness: any) => {
          const {addressHex} = await adaApp.deriveAddress({
            network,
            address: {
              type: HW.AddressType.ENTERPRISE_KEY,
              params: {
                spendingPath: witness.path
              }
            }
          })
          const vkey = C.Vkey.from_bytes(new Uint8Array(Buffer.from(addressHex, 'hex')))
          const signature = C.Ed25519Signature.from_hex(witness.witnessSignatureHex)
          vkeyWitnesses.add(C.Vkeywitness.new(vkey, signature))
        })

        witnessSet.set_vkeys(vkeyWitnesses)
        return witnessSet
      },
      signMessage: () => new Promise((res, rej) => rej("Ledger can't sign messages yet")),
      submitTx: lucid.provider.submitTx
    } as any

    return serializableWallet
  }
)

class WalletAtProviderDoesNotExist extends Error {
  constructor(public wallet: Wallet) {
    super(wallet.provider + '.' + wallet.address)
    this.name = 'WalletAtProviderDoesNotExist'
  }
}
class WalletAtAddressDoesNotExist extends Error {
  constructor(public wallet: Wallet) {
    super(wallet.provider + '.' + wallet.address)
    this.name = 'WalletAtAddressDoesNotExist'
  }
}
class WalletNotSelectedError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = 'WalletNotSelectedError'
  }
}
class AddressHasNoPaymentCredentialsError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = 'AddressHasNoPaymentCredentialsError'
  }
}

export const getStoreWallet = (thunkAPI: ThunkAPI): Result<Wallet, WalletNotSelectedError> => {
  const wallet = thunkAPI.getState().wallet.wallet
  return Result.fromNullable(wallet, new WalletNotSelectedError())
}

export const getWalletProvider = (
  storeWallet: Wallet
): Result<WalletApi, WalletAtProviderDoesNotExist | WalletAtAddressDoesNotExist> => {
  return Result
    .fromNullable(walletApiByProviderByAddress[storeWallet.provider], new WalletAtProviderDoesNotExist(storeWallet))
    .chainR(walletByAddress => Result.fromNullable(walletByAddress[storeWallet.address], new WalletAtAddressDoesNotExist(storeWallet)))
}

export const getWalletFeeAddress = (thunkAPI: ThunkAPI): string | null => {
  return thunkAPI.getState().wallet.feeAddress
}

export const getPaymentCredentialFromWallet = (wallet: Wallet): Result<Lucid.Credential, AddressHasNoPaymentCredentialsError> => {
  const paymentCredential = new Utils(lucid).getAddressDetails(wallet.address).paymentCredential

  return Result.fromNullable(paymentCredential, new AddressHasNoPaymentCredentialsError())
}

// SELECTORS

export const selectPartialWallet: (state: RootState) => St.PartialWallet = (state: RootState) => state.wallet.partialWallet
export const selectPartialWalletUtxos: (state: RootState) => St.Utxo[] = (state: RootState) => state.wallet.partialWallet.utxos
export const selectWallet: (state: RootState) => (UITypes.Wallets.Wallet | null) = (state: RootState) => state.wallet.wallet
export const selectShowWalletSelect: (state: RootState) => boolean = (state: RootState) => state.wallet.showWalletSelect

export const selectWalletLovelaceAmount = (state: RootState): Big => {
  const utxos = state.wallet.partialWallet.utxos
  return sumAssets(utxos, 'lovelace')
}

export const selectWalletAdaAmount = (state: RootState): Big => {
  return lovelaceToAda(selectWalletLovelaceAmount(state))
}

export const sumAssets = (utxos: St.Utxo[], assetClass: string): Big => {
  let sum = Big(0)
  for (const utxo of utxos) {
    const amountString = utxo.assets[assetClass]
    const quantity = amountString === undefined ? Big(0) : Big(amountString)
    sum = sum.add(quantity)
  }
  return sum
}

export const selectWalletOadaletAmount = (state: RootState): Big => {
  const utxos = state.wallet.partialWallet.utxos
  const assetClass =  `${oadaNetwork.oadaPolicyId}${oadaNetwork.oadaTokenName}`
  return sumAssets(utxos, assetClass)
}

export const selectWalletSoadaletAmount = (state: RootState): Big => {
  const utxos = state.wallet.partialWallet.utxos
  return sumAssets(utxos, `${oadaNetwork.soadaPolicyId}${oadaNetwork.soadaTokenName}`)
}

export const selectWalletOadaLpAmount = (state: RootState): Big => {
  const utxos = state.wallet.partialWallet.utxos
  const lpToken = selectOadaLpToken(state)
  if (lpToken === undefined)
    return Big(0)
  return sumAssets(utxos, `${lpToken.policyId}${lpToken.tokenName}`)
}

export const selectWalletOptimizAmount = (state: RootState): Big => {
  const utxos = state.wallet.partialWallet.utxos
  const assetClass =  `${oadaNetwork.optimizPolicyId}${oadaNetwork.optimizTokenName}`
  return assetClass ? sumAssets(utxos, assetClass) : Big(0)
}


export const selectWalletTokenCount = (currencySymbol: string, tokenName: string) => (state: RootState): Big => {
  const utxos = state.wallet.partialWallet.utxos
  const targetAssetName = currencySymbol + tokenName
  let sum = Big(0)
  for (const utxo of utxos) {
    const assets = utxo.assets
    for (const [k, quantity] of Object.entries(assets)) {
      if (targetAssetName === k) {
        sum = sum.add(Big(quantity))
        break;
      }
    }
  }
  return sum
}

export const selectWalletBondTokenAssetsByTokenName = (
  bondTokenPolicyIds: string[]
) => (
  state: RootState
): { [tokenName: string]: Big } => {
  const utxos = state.wallet.partialWallet.utxos
  return getAssetsByTokenNameByCs(bondTokenPolicyIds, utxos)
}

// given currency symbols get the token name to quantity map for all the currency symbols
// doesn't add token name if quantity is 0
const getAssetsByTokenNameByCs = (currencySymbols: string[], utxos: St.Utxo[]): { [tokenName: string]: Big } => {
  const targetCurrencySymbols = new Set(currencySymbols)
  const tokenAssets: { [tokenName: string]: Big } = {}
  for (const utxo of utxos) {
    const assets = utxo.assets
    for (const [k, quantity] of Object.entries(assets)) {
      const currencySymbol = k.slice(0, 56)
      if (targetCurrencySymbols.has(currencySymbol) && Big(quantity).gt(Big(0))) {
        const tokenName = k.slice(56)
        const prevQuantity = tokenAssets[tokenName]
        if (prevQuantity) {
          tokenAssets[tokenName] = prevQuantity.add(Big(quantity))
        } else {
          tokenAssets[tokenName] = Big(quantity)
        }
      }
    }
  }
  return tokenAssets
}

const getAssetsMap = (currencySymbols: string[], utxos: St.Utxo[]): { [poolCs: string]: { [tokenName: string]: Big } } => {
  const targetCurrencySymbols = new Set(currencySymbols)
  const tokenAssets: { [poolCs: string]: { [tokenName: string]: Big } } = {}
  for (const utxo of utxos) {
    const assets = utxo.assets
    for (const [k, quantity] of Object.entries(assets)) {
      const currencySymbol = k.slice(0, 56)
      if (targetCurrencySymbols.has(currencySymbol) && Big(quantity).gt(Big(0))) {
        if (tokenAssets[currencySymbol] === undefined) {
          tokenAssets[currencySymbol] = {}
        }
        const tokenName = k.slice(56)
        const prevQuantity = tokenAssets[currencySymbol][tokenName]
        if (prevQuantity) {
          tokenAssets[currencySymbol][tokenName] = prevQuantity.add(Big(quantity))
        } else {
          tokenAssets[currencySymbol][tokenName] = Big(quantity)
        }
      }
    }
  }
  return tokenAssets
}

export const selectWalletPoolTokenAssetsByTokenName = (state: RootState): { [tokenName: string]: Big } => {
  const utxos = state.wallet.partialWallet.utxos
  const poolCss = selectPoolCurrencySymbols(state)
  if (isNullOrErr(poolCss)) return {}
  return getAssetsByTokenNameByCs(poolCss, utxos)
}

export const selectWalletPoolTokenAssets = (state: RootState): { [poolCs: string]: { [tokenName: string]: Big } } => {
  const utxos = state.wallet.partialWallet.utxos
  const poolCss = selectPoolCurrencySymbols(state)
  // if (isNullOrErr(poolCss)) return {}
  return getAssetsMap(poolCss, utxos)
}

export const selectWalletBondTokenAssets = (state: RootState): { [bondCs: string]: { [tokenName: string]: Big } } => {
  const utxos = state.wallet.partialWallet.utxos
  const bondCss = selectBondTokenCurrencySymbols(state)
  // if (isNullOrErr(bondCss)) return {}
  return getAssetsMap(bondCss, utxos)
}

export const selectWalletOptimNft = (state: RootState): St.AssetClass | null => {
  const utxos = state.wallet.partialWallet.utxos
  const tokenNames = state.bondGetters.scriptParamsSet.map(params => params.inboxNftTokenName)
  const assetsMap = getAssetsMap([network.inboxNftCs], utxos)
  if (tokenNames.length > 0) {
    const inboxNftTn = tokenNames[0]
    const hasInboxNft = assetsMap?.[network.inboxNftCs]?.[inboxNftTn]?.gt(0)
    if (hasInboxNft) {
      return {
        currencySymbol: network.inboxNftCs,
        tokenName: inboxNftTn,
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export const selectWalletOwnerTokenNames = (state: RootState): string[] => {
  const utxos = state.wallet.partialWallet.utxos
  const targetCurrencySymbol = selectOwnNftCurrencySymbol(state)
  return storeUtxosToTokenNamesContainingPolicyId(targetCurrencySymbol, utxos)
}

export const selectWalletJboOwnNftTokenNames = (state: RootState): string[] => {
  const utxos = state.wallet.partialWallet.utxos
  const targetCurrencySymbol = selectJboOwnNftPolicyId(state)
  return storeUtxosToTokenNamesContainingPolicyId(targetCurrencySymbol, utxos)
} 

const storeUtxosToTokenNamesContainingPolicyId = (targetCurrencySymbol: string | null, utxos: St.Utxo[]): string[] => {
  const tokenNames: string[] = []
  for (const utxo of utxos) {
    const assets = utxo.assets
    for (const [k, quantity] of Object.entries(assets)) {
      const currencySymbol = k.slice(0, 56)
      if (targetCurrencySymbol === currencySymbol && Big(quantity).gt(Big(0))) {
        tokenNames.push(k.slice(56))
      }
    }
  }
  return tokenNames
}


export const selectWalletPoolTokenNames = (state: RootState): string[] => {
  const poolTokenNameAssets = selectWalletPoolTokenAssetsByTokenName(state)
  const tokenNames = Object.keys(poolTokenNameAssets)
  return tokenNames
}

// UTILITY

export function lovelaceToAda(lovelace: Big): Big {
  return lovelace.div(Big(1_000_000))
}

function utxoToUtxoRefString(utxo: St.Utxo): string {
  return utxo.txId + "#" + utxo.txIx.toString()
}
